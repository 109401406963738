import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";
import {saveFile} from "@/helpers/mediaHelper";

export class StoreService{
    formDataStore = {};
    constructor() {
        this.formDataStore = {};
    }

    async getStores(fetch){
        let stores = Vue.prototype.$store.getters['common/stores'];
        if(stores.length <= 0 || fetch){
            return await axios.post(Vue.prototype.$globalSettings.api.endpoints.store.stores, {
                sortBy: 'id',
                sort: 'asc'
            })
                .then((response) => {
                    if(response.data.status === 'success'){
                        stores = response.data.stores;
                        Vue.prototype.$store.dispatch('common/setStores', {value: stores});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, stores: stores};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', stores: []};
                })
        }
        return {isSuccess: true, message: '', stores: stores};
    }

    async getStore(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.store.store, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, store: response.data.store};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', store: null};
            });
    }
    async saveStore(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.store.storeSave, this.formDataStore).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.store.storeSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', store: null};
                }
            }
            await this.getStores(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, store: response.data.store};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', store: null};
        });
    }

    async deleteStore(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.store.storeDelete, {
            id: id
        })
            .then(async (response) => {
                await this.getStores(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }
}