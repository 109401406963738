<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content pl-2 pr-2">
            <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" ref="mainDataTable"></CustomDataTable>
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import Vue from 'vue';

    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import CustomDataTable from "../../../components/app/table/CustomDataTable";
    import {isAdmin} from "@/helpers/userHelper";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import {StoreService} from "@/services/store-service";
    let storeService = new StoreService();
    export default {
        name: 'Stores_view',
        data: function(){
            return {
                pageName: 'Stores',
                segments: [
                    { link: false, text: 'Stores' },
                ],
                store: {},
                tableDataUrl: this.$globalSettings.api.endpoints.store.storesDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 60'},
                    {label: 'Name', name: 'name', orderable: true},
                    {label: 'Email', name: 'email', orderable: true},
                    {label: 'Phone', name: 'phone', orderable: true},
                    {label: 'Image', name: 'image', orderable: false, transform: ({data, name}) => data[name]? `<img src="${this.$apiBaseURL + '/' + data[name]}" class="img-sm" />`: '' },
                ],
                searchFilters:{
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc'
                },
                additionalButtons: []
            }
        },
        props: {},
        components: {
            BreadCrumb, CustomDataTable
        },
        computed: {

        },
        methods: {
            add(){
                this.$router.push({name: 'App.Store', params: { id: 0 }});
            },
            edit(data){
                this.$router.push({name: 'App.Store', params: { id: data.id }});
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this store?")) {
                    let loader = this.$loading.show();
                    let response = await storeService.deleteStore(data.id);
                    if (response.isSuccess) {
                        this.$refs.mainDataTable.loadTable();
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loader.hide();
                }
            }
        },
        mounted() {
            if (isAdmin()) {
                this.columns.push({
                    label: '',
                    style: 'width: 60px',
                    component: CustomDataTableDropDownButton,
                    meta: {
                        items: [
                            {
                                label: 'Edit',
                                showLabel: true,
                                iconClass: 'fas fa-edit',
                                event: "click",
                                handler: this.edit,
                                dataPermissionConditions: [{column: 'editable', val: 1}],
                                conditions: [],
                            },
                            {
                                label: 'Delete',
                                showLabel: true,
                                iconClass: 'fas fa-trash',
                                event: "click",
                                handler: this.delete,
                                dataPermissionConditions: [{column: 'editable', val: 1}],
                                conditions: [],
                            },
                        ]
                    }
                });

                this.additionalButtons.push({ label: 'Add', buttonClass: 'btn btn-outline-secondary btn-sm float-right', labelIconClass: 'fas fa-plus', click: this.add });
            }

        }
    }
</script>

<style scoped>

</style>
