<template>
    <div class="dropdown" v-if="items.length > 0">
        <button class="btn btn-outline-primary dropdown-toggle custom-data-table-dropdown-toggle btn-sm" type="button" data-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-ellipsis-h"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
            <button type="button" class="dropdown-item" href="#" v-for="(item, i) in items" :key="i" @click="item.handler(data, item.params)"><i :class="item.iconClass" class="small-fa-icons" v-if="item.iconClass"></i> {{ (item.iconClass && item.showLabel? ' ': '') + (item.showLabel? item.label: '') }}</button>
        </div>
    </div>
<!--    <button :class="classes" @click="click(data, meta.params)" :title="meta.label" v-show="showButton">
        <i :class="meta.iconClass" v-if="meta.iconClass"></i>{{ meta.iconClass && meta.showLabel? ' ': '' + (meta.showLabel? meta.label: '') }}
    </button>-->
</template>

<script>
    import {getPermissionByKey} from "@/helpers/userHelper";
    export default {
        name: "CustomDataTableDropDownButton",
        data: function(){
            return{
                items: [],
            }
        },
        props: {
            data: {},
            meta: {
                type: Object,
                default: () => ({})
            }
        },
        methods:{
            checkConditions: function (conditions){
                let showButton = false;
                if(conditions.length > 0) {
                    for (let i = 0; i < conditions.length; i++) {
                        if (getPermissionByKey(conditions[i])) {
                            showButton = true;
                        }
                    }
                }else{
                    showButton = true;
                }
                //console.log('chk cond', showButton);
                return showButton;
            },
            checkDataPermissionConditions: function (dataPermissionConditions){
                let showButton = false;
                if(dataPermissionConditions.length > 0) {
                    for (let i = 0; i < dataPermissionConditions.length; i++) {
                        //console.log(dataPermissionConditions[i].column, dataPermissionConditions[i].val);
                        if (this.data[dataPermissionConditions[i].column] === dataPermissionConditions[i].val) {
                            if(dataPermissionConditions[i].permission){
                                if (getPermissionByKey(dataPermissionConditions[i].permission)) {
                                    showButton = true;
                                    break;
                                }
                            }else{
                                showButton = true;
                                break;
                            }
                            //console.log('chk data cond', showButton);
                        }
                    }
                }else{
                    showButton = true;
                }

                return showButton;
            },
        },
        mounted() {
            for(let i = 0; i < this.meta.items.length; i++){
                if(this.checkConditions(this.meta.items[i].conditions) && this.checkDataPermissionConditions(this.meta.items[i].dataPermissionConditions)){
                    this.items.push(this.meta.items[i]);
                }
            }
        }
    }
</script>

<style scoped>

</style>